/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


.remove-padding-form-field {
    mat-form-field {
        .mat-form-field-wrapper {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

.reduced-padding-form-field{
    mat-form-field {
        .mat-form-field-wrapper {
            padding: 0 0 1.3rem 0!important;
            margin-bottom: 0rem !important;
        }
    }
}

::-webkit-scrollbar-thumb:hover {
    background: var(--fuse-primary-700);
}

::-webkit-scrollbar {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--fuse-primary);
    border-radius: 10px;
    height: 1px;
}


.button-small-20 {
    .mat-flat-button {
        height: 20px !important;
        min-height: 20px !important;
        max-height: 20px !important;
    }
}

.mat-bg-info {
    background-color: var(--fuse-info) !important;
}

.mat-bg-warn {
    background-color: var(--fuse-warn-300) !important;
}

.mat-bg-success {
    background-color: #138f3a !important;
}

.mat-bg-error {
    background-color: #b01b20 !important;
}

.mat-bg-success-color-white {
    background-color: #138f3a !important;
    color: #F9FAFB !important;
}

.mat-bg-error-color-white {
    background-color: #b01b20 !important;
    color: #F9FAFB !important;
}

.mat-bg-warn-color-white {
    background-color: #ea8024 !important;
    color: #F9FAFB !important;
}

.mat-bg-warn {
    background-color: #ea8024 !important;
}


.custom-mat-menu {
    max-width: none !important;

    .mat-menu-panel {
        max-width: none !important;
    }

    .mat-menu-content {
        max-width: 410px;
        width: 90vw;
        padding: 0px !important;
    }
}

.mat-sort-header-container {
    justify-content: center !important;
}


.dialog-responsive {
    width: 50%;

    .mat-dialog-container {
        padding: 0 !important;
    }

    .mat-dialog-content {
        max-height: 50vh;
        width: 100%;
        padding: 0 !important;
    }
}

.dialog-responsive-filter {
    width: 50%;

    .mat-dialog-container {
        padding: 24px !important;
    }

    .mat-dialog-content {
        max-height: 50vh;
        width: 100%;
    }
}

.dialog-responsive-table {

    .mat-dialog-container {
        padding: 0 !important;
    }

    .mat-dialog-content {
        width: 100%;
    }
}

.dialog-responsive-full-screen {
    min-width: 50%;

    .mat-dialog-container {
        padding: 0 !important;
    }

    mat-tab-header {
        max-width: 74vw !important;
    }

    .mat-dialog-content {
        max-height: 70vh;
    }

}

.dialog-responsive-full-screen-docenti {
    min-width: 50%;

    .mat-dialog-container {
        padding: 0 !important;
    }

    mat-tab-header {
        max-width: 74vw !important;
    }

    .mat-dialog-content {
        max-height: 70vh;
    }

}

.sticky-stepper {
    .mat-horizontal-content-container {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        padding: 0 24px 0 24px;
    }
}

.sticky-stepper-configs {
    .mat-horizontal-content-container {
        max-height: 55vh;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        padding: 0 13px !important;
    }
}



.dialog-responsive-xl {
    min-width: 50%;

    .mat-dialog-container {
        padding: 0 !important;
        max-height: 95vh;
    }
    .mat-dialog-content {
        max-height: 85vh;
    }

}


.table-manage-activity {
    max-height: 50vh
}

.dialog-responsive-choose-area {
    max-width: 30%;
    .mat-dialog-container {
        padding: 0 !important;
    }
    mat-tab-header {
        max-width: 74vw !important;
    }
}

@media only screen and (max-width: 1600px) {
    .table-manage-activity {
        max-height: 40vh
    }

    .dialog-responsive-xl {
        min-width: 50%;

        .mat-dialog-container {
            padding: 0 !important;
            max-height: 95vh;
        }
        .mat-dialog-content {
            max-height: 80vh;
        }

    }
}


@media only screen and (max-width: 760px) {

    .table-manage-activity {
        max-height: unset;
    }
    .dialog-responsive-table {
        width: 100% !important;
        max-width: 100% !important;

        .mat-paginator-range-label {
            display: none !important;
        }

        .mat-paginator-page-size {
            display: none !important;
        }

        .mat-dialog-content {
            padding: 0;
        }
    }

    .dialog-responsive-full-screen {
        width: 98vw !important;
        max-width: unset !important;

        .mat-dialog-container {
            margin: 0 !important;
        }

        .mat-dialog-content {
            margin: 0 !important;
            padding: 0 5px 0 5px !important;
        }
    }

    .dialog-responsive-xl {
        width: 98vw !important;
        max-width: unset !important;

        .mat-dialog-container {
            margin: 0 !important;
        }

        .mat-dialog-content {
            margin: 0 !important;
            padding: 0 5px 0 5px !important;
        }
    }

    .dialog-responsive-choose-area {
        width: 98vw !important;
        max-width: unset !important;

        .mat-dialog-container {
            margin: 0 !important;
        }

        .mat-dialog-content {
            margin: 0 !important;
            padding: 5px !important;
        }
    }
}

@media only screen and (max-width: 760px) {
    .dialog-responsive {
        width: 100%;

        .mat-dialog-container {
            margin: 0 !important;
        }

        .mat-dialog-content {
            margin: 0 !important;
        }

    }
}

.mat-sort-header-content {
    text-align: center !important;
}

.row-archived-courses {
    background-color: var(--fuse-warn-200) !important;
}

.mat-expansion-panel-header {
    min-height: 60px;
    background: #f8fafc;
}

.mat-expanded{
    background: white !important;
}

.mat-cell {
    padding-right: 5px !important;
}

.mat-warning {
    background-color: rgb(251 191 36) !important;
    color: #fff !important;
}

.mat-form-field-custom {
    .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
        min-height: 40px !important;
    }
}

.privacy {

    ol {
        list-style: auto !important;
        padding-left: 30px;
        padding-top: 10px;
    }
    .corpopagina {
        position: relative;
    }

    a, a:hover, a:active, a:focus {
        outline: initial initial 0;
    }

    a {
        color: var(--fuse-primary-500);
        text-decoration: none;
        text-decoration-thickness: initial;
        text-decoration-style: initial;
        text-decoration-color: initial;
        cursor: pointer;
    }

    p {
        padding-bottom: 5px;
    }
}

.error-form {
    color: var(--fuse-warn) !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;

}

.form-mat-hint-error {
    .mat-hint {
        color: var(--fuse-warn) !important;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
    }
}


.dialog-responsive {
    width: 50%;

    .mat-dialog-container {
        padding: 0 !important;
    }

    .mat-dialog-content {
        max-height: 70vh;
        width: 100%;
    }

    mat-tab-header {
        max-width: 74vw !important;
    }
}


.delibera-dialog {

    width: 45vw !important;

    @screen md {
        @apply w-100;
    }

    .mat-dialog-container {
        padding: 0 !important;
    }

}


@media only screen and (max-width: 760px) {
    .dialog-responsive {
        width: 100%;
    }

    .delibera-dialog {

        width: 80vw !important;
    }

}

.calendar-custom {
    .cal-cell {
        text-transform: capitalize !important;
    }

    .cal-week-view .cal-day-headers .cal-header {
        text-transform: capitalize !important;
    }

    .cal-week-view .cal-event {
        white-space: initial !important;
        overflow-y: auto !important;
    }
}

mat-calendar .mat-calendar-body-cell {
    height: 100%;
    position: absolute;
}


.mat-calendar-body-cell {
    position: absolute; // necessary for working in Firefox
}

ngx-mat-datetime-content {
    .mat-calendar-arrow {
        border: none !important;
        width: 10px !important;
        height: 5px !important;
    }

    ngx-mat-calendar-header .mat-calendar-arrow {
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
        border-top-width: 5px !important;
        border-top-style: solid !important;
        width: 0 !important;
        height: 0 !important;
    }

    ngx-mat-month-view .mat-calendar-body-cell {
        position: relative !important;
    }

    ngx-mat-timepicker {
        input.mat-input-element {
            cursor: default;
        }

        .mat-form-field-underline,
        .mat-form-field-subscript-wrapper,
        .mat-form-field-label-wrapper {
            display: none;
        }

        .mat-form-field-appearance-legacy .mat-form-field-infix {
            padding: 0;
            border-top: 0.2rem solid white;
        }

        .mat-form-field {
            line-height: 1rem;
        }
    }

}

input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    font-size: 15px;
}

body.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #94a3b84d !important;
}

.mat-calendar-body-cell.selected {
    background-color: var(--fuse-primary) !important;
    border-radius: 50%;
}

.icon-red-fas {
    .fa, .fas {
        color: red;
    }
}

.fa-fw {
    margin-left: 5px !important;
}

.cal-open-day-events {
    color: #fff;
    background-color: #CBD5E1 !important;
    box-shadow: unset !important;
}

.disabled-date {
    pointer-events: none !important;
    background: grey !important;
    color: white !important;
}
.readonly-all {
    pointer-events: none !important;
}


.warn-date {
    background: #ffebeb !important;
    color: #b40909;
}

.activity-log-request-date {
    background: #fffbeb !important;
    color: #cc600d;
}

.activity-log-validated-date {
    background: #f6fff3 !important;
    color: #3f8a14;
}

.cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2em !important;
}

.cal-month-view .cal-day-cell.cal-in-month {
    cursor: pointer !important;
}

.color-white {
    color: white !important;
}

.color-black {
    color: black !important;
}


.mat-tab-scholarship {
    .mat-tab-body-content {
        padding: 0 !important;
    }

    .mat-tab-group .mat-tab-header .mat-tab-label-container {
        padding: 0 !important;
        padding-bottom: 10px !important;
    }

    .mat-tab-group {
        border: rgb(239 246 255 / var(--tw-bg-opacity));
        border-radius: 0.5rem;
        border-style: solid;
        border-width: 0.3rem;
        padding: 1rem 1rem 0 1rem;
    }
}

.mat-standard-chip-scholarship {
    min-height: 10px !important;
}

.mat-standard-chip-scholarship-select {
}

.custom-mat-menu-action {
    .mat-menu-content {
        overflow-x: hidden;
    }
}

.menu-background {
    background-color: var(--fuse-primary) !important;
}

.mat-menu-user {
    .mat-menu-content {
        padding-bottom: 0 !important;
    }
}

.mat-form-field-select-sottoruolo {
    .mat-form-field-wrapper {
        padding: 0 !important;
    }
}

.sd-body.sd-body--static {
    max-width: calc((var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.sv-components-row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
    background-color: black !important;
}

.sd-root--readonly .sd-item--disabled .sd-item__control-label {
    color: black !important;
    opacity: 1 !important;
}

.sd-input.sd-input--disabled {
    color: black !important;
    opacity: 1 !important;
}

.sd-boolean--disabled .sd-boolean__thumb, .sd-boolean--disabled .sd-boolean__label {
    color: black !important;
    opacity: 1 !important;
}

.mat-form-field-no-padding {
    .mat-form-field-wrapper {
        padding: 0 !important;
    }
}

.dialog-responsive-full-screen-with-padding {
    min-width: 50%;

    .mat-dialog-container {
        padding: 24px !important;
    }

    mat-tab-header {
        max-width: 74vw !important;
    }

}

.angular-editor-toolbar {
    background: #e2e8f0 !important;
    padding: 0.3rem 0 0.3rem 0 !important;
    border: 1.5px solid #CBD5E1 !important;
    display: flex !important;
    border-radius: 0.3rem;
}

.angular-editor-textarea {
    border-radius: 0.3rem;
    border: 1.5px solid #CBD5E1 !important;

    ul {
        padding: revert !important;
        list-style: unset !important;
    }

    ol {
        padding: revert !important;
        list-style: decimal !important;
    }
}

.triple-selection-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 0.5rem !important;
    border-style: solid !important;
    border-width: 0.3rem !important;
}

.category-chip {
    background: #1E293B !important;
    color: white !important;
}

.ticket-typology-mat-option {
    .mat-option-text {
        display: flex !important;
        flex-direction: row !important;
        gap: 0.5rem !important;
        align-items: center;
        padding: 0.7rem;
    }

    height: unset !important;
    padding: 0 !important;
    white-space: unset !important;
    line-height: unset !important;
}

.list-style-plain {
    ul {
        padding: revert !important;
        list-style: disc !important;
    }

    ol {
        padding: revert !important;
        list-style: decimal !important;
    }
}

.support-alert-max-messages {
    .fuse-alert-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.add-ticket-mat-hint {
    font-size: 13px !important;
}

.mat-form-field-subscript-wrapper {
    margin-top: 0.45em;
}

.add-ticket-mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-form-field-prefix {
        top: .15em !important;
    }
}


.info-compilazione-alert {
    .fuse-alert-container {
        width: 100%;
    }

    .fuse-alert-content {
        width: 100%;
    }
}

.mat-form-field-input-upload {
    .mat-form-field-wrapper {
        padding: unset !important;
    }
}

.add-course-mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0.1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-form-field-prefix {
        top: .15em !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

.archived-activities-toggle {
    border-radius: 6rem !important;;
    border: 0.2rem solid #B6BFCA !important;;
    padding: 0.35rem !important;;
}

.add-mobilita-mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0.1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-form-field-prefix {
        top: .15em !important;
    }
}

.small-padding-mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-form-field-prefix {
        top: .05em !important;
    }
}

.audit-topbar-mat-form-field {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0.05rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-form-field-prefix {
        top: .15em !important;
    }
}

.select-autocomplete-form-field {
    .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.select-autocomplete-form-field-external {
    .mat-form-field-subscript-wrapper {
        position: unset !important;
    }

    .mat-form-field-wrapper {
        padding: 0 !important;
        padding-bottom: 0.3rem !important;
    }
}

.dialog-approval-wrap-with-custom > div {
    min-width: 80vw !important;
}

.mobility-search-field {
    .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.mat-form-field-topbar-filter {
    .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 0 0.3rem 0 !important;
    }
}

.bg-active-custom {
    background-color: #1795d3 !important;
}

.add-delibera-button {
    max-height: unset !important;
    height: 3.5rem !important;
    margin-top: 0.2rem !important;
    padding: 1rem !important;
    min-width: unset !important;
}

::-webkit-calendar-picker-indicator {
    height: 1.35rem;
    width: 1.35rem;
    cursor: pointer;
    margin-right: 0.2rem;
    position: absolute;
    top: 0.4rem;
    right: 0;
    background-image: url("/assets/images/ui/angular-material/datepicker_icon.png");
}

.licenses-panel {
    .mat-expansion-panel-body {
        padding: 1rem;
        background: #edf1f5;
    }

    .mat-expansion-panel-header.mat-expanded {
        height: 2rem;
    }
}

.audit-panel {
    .mat-expansion-panel-body {
        padding: 1rem;
    }

    .mat-expansion-panel-header.mat-expanded {
        height: 2rem;
    }
}

.studenti-topbar-filter-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}

.datetime-form-field {
    .mat-form-field-wrapper {
        .mat-form-field-flex {
            height: 60px !important;
        }
    }
}

.input-amount-mat-form-field {
    .mat-form-field-wrapper {
        margin-bottom: 0 !important;
    }
}

.mat-tab-group-registro-attivita{
    .mat-tab-body-wrapper{
        height: 100% !important;
        .mat-tab-body{
            height: 100% !important;
            .mat-tab-body-content{
                height: 100% !important;
            }
        }
    }
}

@media(max-width: 800px) {
    .mat-tab-group .mat-tab-body-content {
        padding: 20px 5px;
    }
}

.mat-select-panel mat-option.mat-option {
    height: unset !important;
    padding: 10px !important;
    margin: 2px 0 !important;
    overflow: visible !important;
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
}

.mat-option-text.mat-option-text {
    white-space: normal !important;
    line-height: 20px !important;
}

// to disable bg scrolling when modals are open
body:has(.cdk-overlay-container):has(.cdk-global-overlay-wrapper):has(.dialog-responsive-full-screen){
    overflow-y: hidden;
}

body:has(.cdk-overlay-container):has(.cdk-global-overlay-wrapper):has(div):has(mat-dialog-container){
    overflow-y: hidden;
}

.esterni-list-legend-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}

.training-offer-publish-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}

.training-offer-info-compilazione-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 0 !important;
}

.training-offer-info-compilazione-content-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    background: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 0 !important;
}

.giorni-retribuiti-retribuibili-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    background: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}


.ore-registro-attivita-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    background: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}


.circle-event {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
    border-style: solid;
    border-width: 1px;
}

.multi-line-button{
    overflow-x: hidden !important;
    white-space: pre-wrap !important;
    max-height: unset;
    height: unset;
    padding: 0.5rem 0.6rem 0.5rem 0.6rem !important;
    line-height: 1.2rem !important;
}

.retribution-preview-alert{
    width: 100% !important;
    .fuse-alert-container{
        width: 100% !important;
        .fuse-alert-content{
            width: 100% !important;
            .fuse-alert-message{
                width: 100% !important;
            }
        }
    }
}
ul {
    list-style-type: disc !important;
    padding-left: 30px  !important;
}

.activities-tab-group{
    .mat-tab-header{
       .mat-tab-label-container{
           .mat-tab-list{
               .mat-tab-labels{
                   .mat-tab-label{
                       width: 25% !important;
                   }
               }
           }
       }
    }
}

.mat-tab-disabled{
    opacity: 0.5 !important;
}

.training-offer-areas-div {
    border: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
    border-radius: 1rem !important;;
    border-style: solid !important;;
    border-width: 0.2rem !important;;
    padding: 1rem !important;
}

.white-spinner{
    svg {
        circle{
            stroke: white !important;
        }
    }
}

.mat-radio-label{
    white-space: normal !important;
}

.mat-tab-group-container-no-padding {
    .mat-tab-header{
        .mat-tab-label-container{
            padding: 0 !important;
        }
    }
}

.mat-tab-group-container-no-padding-y {
    .mat-tab-header{
        .mat-tab-label-container{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

.mat-button-toggle-checked{
    background: #B6BFCA !important;
}

.mat-button-toggle-label-content{
    padding: 0 !important;
}

.mat-button-toggle-ripple{
    border-radius: 5px !important;
}

.mat-form-field-border-red.mat-focused{
    .mat-form-field-wrapper{
        .mat-form-field-flex{
            .mat-form-field-outline{
                .mat-form-field-outline-start, .mat-form-field-outline-end{
                    border-color: #e712129c !important;
                    border-width: 2px !important;
                }
                .mat-form-field-outline-gap{
                    border-top-color: rgba(255, 255, 255, 0) !important;
                    border-bottom-color: #e712129c !important;
                    border-width: 2px !important;
                }
            }
        }
    }
}

.mat-form-field-border-red{
    .mat-form-field-wrapper{
        .mat-form-field-flex{
            .mat-form-field-outline{
                .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap{
                    border-color: #e712129c !important;
                    border-width: 2px !important;
                }
            }
        }
    }
}

.fuse-alert-content-full-width{
    .fuse-alert-container{
        width: 100% !important;
        .fuse-alert-content{
            width: 100% !important;
        }
    }
}

.generic-table-disabled-row{
    color: #b0b0b0 !important;
}


.apexcharts-tooltip{
    max-width: 800px;
}

.apexcharts-tooltip-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.class-hide-tab4 .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label:nth-child(4) {
    opacity: 0 !important;
}

.outlined-field {
    border-radius: 0.5rem;
    border: 0.2rem solid rgb(239 246 255 / var(--tw-bg-opacity));
    padding: 0.8rem;
}

.outlined-field-white {
    border-radius: 0.5rem;
    border: 0.2rem solid #ffffff;
    padding: 0.8rem;
}


.div-button-sticky {
    position: sticky;
    bottom: 0;
    padding-top: 5px;
    background-color: white;
}


.div-button-sticky-add-edit-category {
    position: sticky;
    bottom: 0;
    padding-top: 5px;
    background-color: rgb(241 245 249) !important;
    z-index: 1000;
}

.stepper-bg-config-0 .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container mat-step-header:nth-child(1){
    background-color: rgb(241 245 249) !important;
}

.stepper-bg-config-1 .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container mat-step-header:nth-child(3){
    background-color: rgb(241 245 249) !important;
}

.stepper-bg-config-2 .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container mat-step-header:nth-child(5){
    background-color: rgb(241 245 249) !important;
}

.stepper-bg-config-3 .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container mat-step-header:nth-child(7){
    background-color: rgb(241 245 249) !important;
}

.stepper-bg-config-4 .mat-horizontal-stepper-wrapper .mat-horizontal-stepper-header-container mat-step-header:nth-child(9){
    background-color: rgb(241 245 249) !important;
}

.mat-stepper-add-category {
    .mat-horizontal-content-container {
        background-color: rgb(241 245 249) !important;
    }
    .mat-step-label-selected{
        font-size: larger !important;
        font-weight: bold !important;
    }
}

.mat-stepper-add-category-unset .mat-step-label-selected{
    font-size: unset !important;
    font-weight: bold !important;
}


.mat-paginator .mat-paginator-container {
    padding: 0 16px !important;
    justify-content: space-between;
}

.mat-paginator .mat-paginator-container .mat-paginator-range-actions {
    margin: 4px 0;
}

.mat-paginator .mat-paginator-container .mat-paginator-page-size {
    align-items: center;
    min-height: 40px;
    margin: 4px;
}


.mat-horizontal-stepper-header{
    height: 50px !important;
}

.mat-horizontal-content-container{
    padding: 0 15px 0 15px !important;
}

.mat-stepper-horizontal{
    padding: 0 !important;
}

.mat-expansion-panel-no-body-padding{
    .mat-expansion-panel-body{
        padding: 0 !important;
    }
}

.mat-expansion-panel-offer-plan{
    mat-expansion-panel-header {
        background: #f2f6fa !important;
    }
}

.mat-expansion-panel-offer-plan-mobile{
    mat-expansion-panel-header {
        height: 100px !important;
    }
}

.mat-tab-group-offer-plan{
    .mat-tab-body-content{
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.configurations-tab-group{
    .mat-tab-label{
        min-width: 110px !important;
    }
    .mat-tab-body-content{
        padding-top: 15px !important;
    }
}


.mat-tab-label .mat-tab-label-content {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    line-height: normal !important;
}

.mat-tab-group-info-ciclo{
    .mat-tab-label-content{
        min-width: 150px !important;
    }
    .mat-tab-body-content{
        padding: 15px !important;
    }
}

.caricamento-delibere-radio-btn{
    .mat-radio-inner-circle{
        background-color: #64748B !important;
    }
    .mat-radio-outer-circle{
        border-color: #64748B !important;
    }
}

.height-unset{
    height: unset !important;
}

.generic-table-removed-row{
    background-color: #ffc8c8 !important;
}

.generic-table-highlight-row-green{
    background-color: #77dd7760 !important;
}

.generic-table-highlight-row-yellow{
    background-color: #Ffff6680 !important
}

.mat-expansion-panel-year-passing-config{
    .mat-expansion-panel-body{
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.mat-tab-group-reduced-body-padding{
    .mat-tab-body-content{
        padding-top: 18px !important;
    }
}

.mat-tab-group-no-body-padding{
    .mat-tab-body-content{
        padding-top: 0 !important;
    }
}

.fuse-alert-reduced-padding{
    .fuse-alert-container{
        padding: 8px !important;
    }
}

.fuse-alert-reduced-padding-medium{
    .fuse-alert-container{
        padding: 10px !important;
    }
}

.fuse-alert-modify-subrole{
    .fuse-alert-container{
        font-size: 12px !important;
        padding: 10px 14px !important;
    }
    max-width: 320px !important;
}


.chip-administration-roles{
    min-height: unset !important;
    height: unset !important;
    padding: 2px 10px 2px 10px !important;
    margin: 2px !important;
}

.mat-form-calendario{
    .mat-form-field-infix{
        padding: 0 !important;
        margin: 0 !important;
    }
}
